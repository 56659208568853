<template>
  <div class="user-wrapper">
    <div class="content-box">
      <a class="action" @click="openChat"><i class="iconfont icon-kefu" style="color: white;"><span
        style="margin-left: 4px;color: white;">{{ $t('user.service.support') }}</span></i></a>
      <a-divider type="vertical" />
      <a class="action" @click="openHelp" style="color: white;"><i
        class="iconfont icon-wendang"
        style="color: white;"><span
          style="margin-left: 4px;color: white;">{{ $t('user.service.doc') }}</span></i></a>
      <!--      <a-divider type="vertical" />-->
      <!--      <a-dropdown class="action" @click="openHelp">-->
      <!--        <div><i class="iconfont icon-lvzhou_yuyanqiehuan" style="position: relative;top:1px;color: white;"></i><span-->
      <!--          style="margin-left: 8px;color: white;">{{ lang().indexOf('ja')===0?'日本語':'English' }}</span>-->
      <!--          <a-icon type="down" style="color:white;" />-->
      <!--        </div>-->
      <!--        <a-menu slot="overlay" @click="onLangClick">-->
      <!--          <a-menu-item key="ja-JP">-->
      <!--            <a>日本語</a>-->
      <!--          </a-menu-item>-->
      <!--          <a-menu-item key="en-US">-->
      <!--            <a>English</a>-->
      <!--          </a-menu-item>-->
      <!--        </a-menu>-->
      <!--      </a-dropdown>-->

      <a-divider type="vertical" />
      <a-dropdown>
        <div class="action ant-dropdown-link">
          <span style="color: white;">{{ nickname() }}</span>
          <a-icon type="down" style="color:white;margin-left: 4px;" />
        </div>
        <a-menu slot="overlay" class="user-dropdown-menu-wrapper" style="width: 240px !important;">
          <a-menu-item key="1" style="width: 240px !important;">
            <a href="javascript:;" @click="showUInfo">
              <a-icon type="key" />
              <span>{{ $t('user.accountSetting') }}</span>
            </a>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="12" style="width: 240px !important;">
            <a href="javascript:;" @click="showNameChange">
              <a-icon type="user" />
              <span>{{ $t('user.name.change.title') }}</span>
            </a>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="3" style="width: 240px !important;">
            <a href="javascript:;" @click="handleLogout">
              <a-icon type="logout" />
              <span>{{ $t('user.signout') }}</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import NoticeIcon from '@/components/NoticeIcon'
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import { changeUserLanguage } from '@/api/data'

export default {
  name: 'UserMenu',
  data () {
    return {
      role: 0
    }
  },
  components: {
    NoticeIcon
  },
  created () {
    var role = Vue.ls.get('role')
    this.role = role
    var locInfo = window.localStorage.getItem('local')
    if (locInfo) {
      var locObj = JSON.parse(locInfo)
      this.cLocal = locObj.name
    }
  },
  methods: {
    ...mapActions(['Logout']),
    ...mapGetters(['nickname', 'lang']),
    handleLogout () {
      const that = this

      this.$confirm({
        title: this.$t('user.tip'),
        content: this.$t('user.signOutTip'),
        okText: this.$t('user.action.ok'),
        cancelText: this.$t('user.action.cancel'),
        onOk () {
          return that.Logout({}).then(() => {
            // clear db
            window.location.reload()
          }).catch(err => {
            that.$message.error({
              title: 'Error',
              description: err.message
            })
          })
        },
        onCancel () {
        }
      })
    },
    onLangClick (e) {
      window.localStorage.setItem('mLang', e.key)
      changeUserLanguage({
        lang: e.key
      }).then(res => {
        window.location.reload()
      })
    },
    showUInfo () {
      this.$emit('showAccountModal')
    },
    showNameChange () {
      this.$emit('showNameModal')
    },
    openChat () {
      if (navigator.language.indexOf('ja') === 0) {
        window.open('http://chat.reflexwireless.jp/chat/1i6jl3i7m', '_blank')
      } else {
        window.open('http://chat.reflexwireless.jp/chat/1iaqcdmak', '_blank')
      }
    },
    openHelp () {
      if (navigator.language.indexOf('ja') === 0) {
        window.open('https://www.reflexwireless.jp/cloud-manager/', '_blank')
      } else {
        window.open('https://www.reflexwireless.com/en/cloud-manager/', '_blank')
      }
    }
  }
}
</script>
<style lang='less' scoped>
.action {
  display: inline-block;
  border-radius: 4px;
  hight: 32px !important;
  line-height: 32px;
  padding: 0;
  color: white;
}
</style>
