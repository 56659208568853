import { axios } from '@/utils/request'

export function loadPermissionList (parameter) {
  return axios({
    url: '/share/permission/list',
    method: 'post',
    data: parameter
  })
}

export function switchEmailAlertEnable (parameter) {
  return axios({
    url: '/share/email/push/status/set',
    method: 'post',
    data: parameter
  })
}

export function changeUserMembership (parameter) {
  return axios({
    url: '/share/user/membership/change',
    method: 'post',
    data: parameter
  })
}

export function exportDataToExcel (parameter) {
  return axios({
    url: '/common/data/export',
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}

export function bindUser (parameter) {
  return axios({
    url: '/share/user/bind',
    method: 'post',
    data: parameter
  })
}

export function loadKeiyoFiveMonthShareData (parameter) {
  return axios({
    url: '/five/month/share/data',
    method: 'post',
    data: parameter
  })
}

export function loadTestDataList (parameter) {
  return axios({
    url: '/user/test/data/list',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function checkAlcSensorWebhookConfig (parameter) {
  return axios({
    url: '/alc/sensor/webhook/url/check',
    method: 'post',
    timeout: 6000,
    data: parameter
  })
}

export function saveAlcSensorWebhookConfig (parameter) {
  return axios({
    url: '/alc/sensor/webhook/setting/save',
    method: 'post',
    timeout: 6000,
    data: parameter
  })
}

export function loadAlcSensorWebhookConfig (parameter) {
  return axios({
    url: '/alc/sensor/webhook/setting/load',
    method: 'post',
    timeout: 6000,
    data: parameter
  })
}

export function sendMockAlcSensorWebhookData (parameter) {
  return axios({
    url: '/alc/sensor/webhook/mock',
    method: 'post',
    timeout: 6000,
    data: parameter
  })
}

export function switchEnableAlcSensorWebhookConfig (parameter) {
  return axios({
    url: '/alc/sensor/webhook/enable/switch',
    method: 'post',
    timeout: 6000,
    data: parameter
  })
}

export function getVideoPreviewUrl (parameter) {
  return axios({
    url: '/alc/test/video/url/load',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function downloadDailyShareDataXlsx (parameter) {
  return axios({
    url: '/daily/share/xlsx/download',
    method: 'post',
    timeout: 60000,
    responseType: 'blob',
    data: parameter
  })
}

export function createMembershipOrder (parameter) {
  return axios({
    url: '/user/pay/order/create',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function changeUserLanguage (parameter) {
  return axios({
    url: '/user/language/change',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function loadAdminCurrentAmount (parameter) {
  return axios({
    url: '/alc/sensor/amount/load',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function loadAlcWebhookRecord (parameter) {
  return axios({
    url: '/alc/sensor/webhook/record',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function captureMembershipOrder (parameter) {
  return axios({
    url: '/user/pay/order/capture',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function checkOrderStatus (parameter) {
  return axios({
    url: '/user/order/status/check',
    method: 'post',
    timeout: 60000,
    data: parameter
  })
}

export function resetInfo (parameter) {
  return axios({
    url: '/user/login/pwd/reset',
    method: 'post',
    data: parameter
  })
}

export function resetUserName (parameter) {
  return axios({
    url: '/user/name/reset',
    method: 'post',
    data: parameter
  })
}

export function deleteSharePermission (parameter) {
  return axios({
    url: '/share/permission/del',
    method: 'post',
    data: parameter
  })
}

export function loadKeiyoUserSetting (parameter) {
  return axios({
    url: '/keiyo/user/setting/load',
    method: 'post',
    data: parameter
  })
}

export function loadAdminBindLinkCodeInfo (parameter) {
  return axios({
    url: '/share/auth/link/get',
    method: 'post',
    data: parameter
  })
}

export function loadUserAttendanceRecord (parameter) {
  return axios({
    url: '/user/attendance/stat/load',
    method: 'post',
    data: parameter
  })
}

export function getUserAttendanceRecord (parameter) {
  return axios({
    url: '/user/attendance/info/record',
    method: 'post',
    data: parameter
  })
}

export function loadUserDriveLogList (parameter) {
  return axios({
    url: '/user/drive/log/record',
    method: 'post',
    data: parameter
  })
}
