<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
/* eslint-disable camelcase */

import ja from 'ant-design-vue/lib/locale-provider/ja_JP'
import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { AppDeviceEnquire } from '@/utils/mixin'
import { getI18nLanguage } from '@/locales'

let mLocal = null
const lang = getI18nLanguage()
if (lang.indexOf('ja') === 0) {
  mLocal = ja
} else if (lang.indexOf('zh') === 0) {
  mLocal = zhCN
} else {
  mLocal = enUS
}

export default {
  mixins: [AppDeviceEnquire],
  data () {
    return {
      locale: mLocal
    }
  },
  mounted () {

  },
  created () {

  },
  beforeDestroy () {
    this.$layer.closeAll()
  },
  methods: {

  }

}
</script>
<style>
  @import "./assets/common.css";
  @import "./assets/vue-layer.css";

  #app {
    height: 100%;
    width: 100%;
  }
  body{
    padding: 0;
  }

</style>
