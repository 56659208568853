// ie polyfill
import '@babel/polyfill'
import hl from 'highlight.js'
import 'highlight.js/styles/a11y-dark.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import { VueAxios } from './utils/request'

// mock
// import './mock'
import bootstrap from './core/bootstrap'
import './core/use'
import './permission' // permission control
import './utils/filter' // global filter
import base from './base'
Vue.config.productionTip = false
// 自定义一个代码高亮指令
Vue.directive('highlight', function (el) {
  const blocks = el.querySelectorAll('code')
  blocks.forEach((block) => {
    hl.highlightElement(block)
  })
})

// mount axios Vue.$http and this.$http
Vue.use(VueAxios)

Vue.use(base)

new Vue({
  router,
  store,
  created () {
    bootstrap()
  },
  render: h => h(App)
}).$mount('#app')
