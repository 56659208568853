// eslint-disable-next-line
import {UserLayout, BasicLayout, RouteView, BlankLayout, PageView} from '@/layouts'

export const asyncRouterMap = [

  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    children: [
      // dashboard
      {
        path: '/record',
        name: 'record',
        redirect: '/test/record',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.record', keepAlive: true, icon: 'icon-jilu', permission: ['record'] },
        children: [
          {
            path: '/test/record',
            name: 'TestRecord',
            component: resolve => require(['@/views/data/TestRecord'], resolve),
            meta: { title: 'menu.record', keepAlive: true, permission: ['record'] }
          }
        ]
      },
      {
        path: '/daily',
        name: 'daily',
        redirect: '/daily/data',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.daily.data', keepAlive: true, icon: 'icon-rili', permission: ['record'] },
        children: [
          {
            path: '/daily/data',
            name: 'DailyData',
            component: resolve => require(['@/views/data/DailyData'], resolve),
            meta: { title: 'menu.daily.data', keepAlive: true, permission: ['record'] }
          }
        ]
      },
       {
        path: '/attendance',
        name: 'attendance',
        redirect: '/attendance/stat',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.attendance', keepAlive: true, icon: 'icon-kaoqin', permission: ['driver'] },
        children: [
          {
            path: '/attendance/stat',
            name: 'AttendanceStat',
            component: resolve => require(['@/views/data/AttendanceStat'], resolve),
            meta: { title: 'menu.attendance', keepAlive: true, permission: ['driver'] }
          }
        ]
      },
      {
        path: '/drivelog',
        name: 'drivelog',
        redirect: '/drive/log',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.drivelog', keepAlive: true, icon: 'icon-hangshijilu', permission: ['driver'] },
        children: [
          {
            path: '/drive/log',
            name: 'DriveLog',
            component: resolve => require(['@/views/data/DriveLog'], resolve),
            meta: { title: 'menu.drivelog', keepAlive: true, permission: ['driver'] }
          }
        ]
      },
      {
        path: '/driver',
        name: 'driver',
        redirect: '/driver/list',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.driver', keepAlive: true, icon: 'icon-changyonglvyouyewutubiao_fuzhi_sidao', permission: ['driver'] },
        children: [
          {
            path: '/driver/list',
            name: 'DriverList',
            component: resolve => require(['@/views/data/DriverList'], resolve),
            meta: { title: 'menu.driver', keepAlive: true, permission: ['driver'] }
          }
        ]
      },
      {
        path: '/webhook',
        name: 'webhook',
        redirect: '/web/hook',
        component: RouteView,
        hideChildrenInMenu: true,
        meta: { title: 'menu.webhook', keepAlive: true, icon: 'icon-webhook', permission: ['record'] },
        children: [
          {
            path: '/web/hook',
            name: 'WebHook',
            component: resolve => require(['@/views/data/WebHook'], resolve),
            meta: { title: 'menu.record', keepAlive: true, permission: ['record'] }
          }
        ]
      },

      {
        path: '*', redirect: '/404', hidden: true
      }
    ],
    meta: { title: '' },
    redirect: '/record'
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/u',
    component: UserLayout,
    redirect: '/u/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: resolve => require(['@/views/user/Login'], resolve)
      },
      {
        path: 'register',
        name: 'register',
        component: resolve => require(['@/views/user/Register'], resolve)
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: resolve => require(['@/views/user/RegisterResult'], resolve)
      }
    ]
  },
  {
    path: '/bind',
    component: BlankLayout,
    redirect: '/bind/driver',
    hidden: true,
    children: [{
      path: '/bind/driver',
      name: 'bind',
      component: resolve => require(['@/views/driver/BindDriver'], resolve)
    }]
  },
  {
    path: '/404',
    component: resolve => require(['@/views/exception/404'], resolve)
  }

]
