import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enUS from './locale/en-US.js'
import zhCN from './locale/zh-CN.js'
import jaJP from './locale/ja-JP.js'
Vue.use(VueI18n)
export const defaultLang = navigator.language
const localStorage = window.localStorage
let mLang = localStorage.getItem('mLang')
// if (!mLang) {
//   mLang = defaultLang
// }
// if (mLang.indexOf('zh') > -1) {
//   mLang = 'zh-CN'
// } else if (mLang.indexOf('ja') > -1) {
//   mLang = 'ja-JP'
// } else {
//   mLang = 'en-US'
// }

const host = window.location.host
if (host === 'cloud.reflexwireless.jp') {
  mLang = 'ja-JP'
} else if (host === 'cloud.reflexwireless.com') {
  mLang = 'en-US'
} else if (host.indexOf('localhost') >= 0 || host === 'cloud.breathmeters.cn') {
  mLang = 'zh-CN'
} else {
  mLang = 'en-US'
}
const i18n = new VueI18n({
  locale: mLang, // 语言标识
  fallbackLocale: 'en-US',
  messages: {
    'en-US': enUS,
    'zh-CN': zhCN,
    'ja-JP': jaJP
  }
})

export function getI18nLanguage () {
  return i18n.locale
}
export function setI18nLanguage (lang) {
  i18n.locale = lang
}
export function i18nRender (key) {
  return i18n.t(`${key}`)
}
export default i18n
